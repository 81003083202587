import { useLocation } from "react-router-dom";
import DewinterForm from "./sections/DewinterForm";
import WinterForm from "./sections/WinterForm";

const Request = (props) => {
    const location = useLocation();

    const winterize = location.pathname === "/winterize";
    const pageTitle = winterize ? "Winterization" : "De-Winterization";
    document.title = `${pageTitle} | Dependable`;

    return (
        <div>
            <div className="bg-center bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/main_bg.jpg')" }}>
                <div className="bg-gradient-to-r from-dependable to-dependable/75">
                    <div className="mx-auto max-w-7xl text-left md:py-20 py-10 px-5">
                        <h1 className="text-lg md:text-6xl text-yellow-500 md:mb-4 uppercase font-light tracking-widest">{pageTitle}</h1>
                        <p className="text-white mt-2 text-lg">Dependable Management</p>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl px-5 mx-auto py-10 ">
                <div className="text-left mb-5 border border-dependable">
                    <div className="relative text-white bg-dependable p-5 text-xl font-bold">
                        <p className="w-5/6">Request {pageTitle}</p>
                    </div>
                    <div className="py-8 px-5">
                        {!winterize && <DewinterForm /> }
                        {winterize && <WinterForm /> }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Request;
